import React from "react";
import FormCompany from "../../../../components/FormCompany";
import { useSnapshotWhere } from "../../../../hooks/useSnapshotWhere";

function ContentFormCompany({ user }) {
  const { data: dataUser, isFetching: fetchingUser } = useSnapshotWhere(
    "users",
    {
      filter: {
        firstCondition: "id",
        secondCondition: "==",
        thirdCondition: user.uid,
      },
    }
  );
  return <FormCompany majorCompanyId={dataUser[0]?.majorCompanyId} />;
}

export default ContentFormCompany;
