import React from "react";
import { Item } from "../components/Table.js/ModalContainer";
import { useParams } from "react-router-dom";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere";
import Loading from "../../../components/Loading";
import Input from "../../../components/Form/Input";

function Compania() {
  const { idCompany, idRequest } = useParams();

  const { data: companyRes, isFetching: loadingCompany } = useSnapshotWhere(
    "companies",
    {
      filter: {
        firstCondition: "uuid",
        secondCondition: "==",
        thirdCondition: idCompany,
      },
    }
  );

  const { data: requestRes, isFetching: loading } = useSnapshotWhere(
    "requests",
    {
      filter: {
        firstCondition: "uuid",
        secondCondition: "==",
        thirdCondition: idRequest,
      },
    }
  );

  const company = companyRes[0];
  const request = requestRes[0];

  if (loading || loadingCompany) return <Loading />;

  if (!company || !request) return <p>No hay datos</p>;

  return (
    <div className="text-left">
      <div className="grid grid-cols-2 gap-5">
        <div className="bg-white p-3 rounded-lg">
          <p>Empresa: {company.name_company}</p>
          <p>
            Representante Legal: {company.name} {company.lastName}
          </p>
          <p>Email: {company.email}</p>
          <div className="bg-gray-200 rounded-lg p-1 w-fit my-2">
            Empleado: {request.name} {request.lastName}
          </div>

          <hr className="my-3" />
          <p>Archivos de la empresa:</p>
          <div className="grid grid-cols-3 gap-3">
            <Item href={company.cam_comercio} name="Cámara de Comercio" />
            <Item href={company.fot_cedula} name="Cédula Rep. Legal" />
            <Item href={company.rut} name="RUT" />
          </div>
        </div>

        <div className="bg-white p-3 rounded-lg text-center flex flex-col items-center justify-center">
          <h3 className="font-semibold text-xl">Generar Factura</h3>

          <button className="bg-blue-700 rounded-lg px-8 py-1 mt-3 text-white">
            Generar
          </button>
        </div>

        <div className="bg-white p-3 rounded-lg text-center flex flex-col items-center justify-center">
          <Input
            label="Enviar email con la factura del mes"
            type="file"
            // onChange={(e) => {
            //   setFiles({ ...files, cert_laboral: e.target.files[0] });
            // }}
            black
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />

          <button className="bg-blue-700 rounded-lg px-8 py-1 mt-3 text-white">
            Enviar
          </button>
        </div>

        <div>
          <p>Emails enviados</p>

          <div className={`relative shadow-md mt-3 rounded-lg`}>
            <table className="w-full text-sm text-left rtl:text-right text-gray-50">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Mes
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Estado
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                <tr className="odd:bg-white even:bg-gray-50  border-b">
                  <td className="px-6 py-4 text-black">1</td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Noviembre
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <p className="bg-green-400 rounded-lg p-1 w-fit">Enviado</p>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compania;
