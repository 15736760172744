import React from "react";
import Spinner from "../../../components/Spinner";
import useUpdateDoc from "../../../hooks/useUpdateDoc";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatCurrencyTable } from "../../../utils/formatCurrencyTable";

function TablePagos({ loading, schedule, payment, data }) {
  const { handleUpdate } = useUpdateDoc();
  const handleUpdatePayment = (id) => {
    const existId = data?.payments?.find(
      (payment) => payment === id.toString()
    );

    if (existId) {
    } else {
      // Confirmación antes de insertar el pago
      const isConfirmed = window.confirm(
        "¿Está seguro de que desea insertar este pago? Una vez insertado, no se puede borrar."
      );

      if (isConfirmed) {
        // El usuario confirmó, insertamos el pago
        const insertArray = data.payments.push(id.toString());

        const newArray = data.payments;

        handleUpdate("requests", data.id, {
          payments: newArray,
        });
      }
    }
  };
  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th className="px-6 py-3">Cuotas</th>
          <th className="px-6 py-3">Fecha de pago</th>
          <th className="px-6 py-3">Valor préstamo</th>
          <th className="px-6 py-3">Cuota a pagar</th>
          <th className="px-6 py-3">Capital</th>
          <th className="px-6 py-3">Interés</th>
          <th className="px-6 py-3">Saldo deuda</th>
          {payment && <th className="px-6 py-3">Pago</th>}
        </tr>
      </thead>
      <tbody className="bg-white">
        {loading ? (
          <tr>
            <td colSpan="100%" className="text-center">
              <Spinner />
            </td>
          </tr>
        ) : (
          schedule.map((row) => (
            <tr
              className="bg-white border-b  dark:border-gray-700"
              key={row.cuota}
            >
              <td className="px-6 py-4">{row.cuota}</td>
              <td className="px-6 py-4">
                {row.fechaPago === "Invalid Date" ? "" : row.fechaPago}
              </td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row.valorPrestamo)}
              </td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row.cuotaAPagar)}
              </td>
              <td className="px-6 py-4">${formatCurrencyTable(row.capital)}</td>
              <td className="px-6 py-4">${formatCurrencyTable(row.interes)}</td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row.saldoDeuda)}
              </td>
              {payment && (
                <td className="px-6 py-4">
                  <div
                    onClick={() => handleUpdatePayment(row.cuota)}
                    className={` rounded-full  w-5 h-5 border-gray-700 cursor-pointer ${
                      row.payment ? "bg-blue-700" : "border"
                    } `}
                  />
                </td>
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default TablePagos;
