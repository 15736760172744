import React, { useState } from "react";
import useUpdateDoc from "../../../hooks/useUpdateDoc.js";
import useInsertDoc from "../../../hooks/useInsertDoc.js";
import dayjs from "dayjs";
import { uploadFile } from "../../../FirebaseConfig.ts";

function ModalContainer({ currentRequest }) {
  const [fetching, setFetching] = useState(false);
  const [doneStatus, setDoneStatus] = useState(false);
  const [formData, setFormData] = useState({
    status: currentRequest.status || "pendiente",
    tasa: currentRequest.tasa || "",
    month: currentRequest.month || "",
    amount: currentRequest.ammount || "",
    cellphone: currentRequest.cellphone || "",
    address: currentRequest.address || "",
    email: currentRequest.email || "",
    expedition: currentRequest.expedition || "",
    date: currentRequest.dateStart || "",
    name: currentRequest.name || "",
    lastName: currentRequest.lastName || "",
    identity: currentRequest.identity || "",
  });

  const [files, setFiles] = useState({
    cert_laboral: null,
    comp_nomina: null,
    fot_cedula: null,
    dec_renta: null,
  });

  const { handleUpdate } = useUpdateDoc();
  const { handleInsert } = useInsertDoc();

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: e.target.files[0],
      }));
    } else if (name === "tasa") {
      const sanitizedValue = value.replace(",", ".").replace(/[^\d.]/g, "");
      const parts = sanitizedValue.split(".");
      const formattedValue = parts[0] + (parts[1] ? "." + parts[1] : "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUpdateRequest = async () => {
    try {
      setFetching(true);

      // Subir archivos
      const uploadPromises = Object.keys(files).map(async (key) => {
        const file = files[key];
        if (file) {
          const url = await uploadFile(file, `${formData.name}/${key}`);
          return { [key]: url };
        }
        return null;
      });

      const uploadResults = await Promise.all(uploadPromises);

      const fileUrls = uploadResults.reduce((acc, curr) => {
        if (curr) {
          return { ...acc, ...curr };
        }
        return acc;
      }, {});

      const data = { ...formData, ...fileUrls };

      if (data.status === "aprobado") {
        const res = await handleInsert("requests", {
          ...data,
          ...currentRequest,
          numberCredit: dayjs().format("YYYYMMDDmms"),
          knowMoney: true,
          payments: [],
          status: "aprobado",
          start_date: data.date,
          date: data.date,
          dateStart: data.date,
        });
        await handleUpdate("requests", res.id, { uuid: res.id });
        await handleUpdate("simulator", currentRequest.id, {
          status: data.status,
          start_date: data.date,
          date: data.date,
          dateStart: data.date,
        });
      } else {
        await handleUpdate("simulator", currentRequest.id, {
          ...data,
          start_date: data.date,
          date: data.date,
          dateStart: data.date,
        });
      }

      setFetching(false);
      setDoneStatus(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneStatus(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-h-[500px] overflow-y-auto">
      <h2 className="text-2xl font-semibold mb-2">
        Editar solicitud de: {currentRequest.name} {currentRequest.lastName}
      </h2>
      <p className="text-gray-600 mb-4">
        Simulación realizada por: {currentRequest.userName}{" "}
        {currentRequest.lastName}
      </p>
      <hr className="mb-6" />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Fecha de inicio del crédito:
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div>
          <label
            htmlFor="status"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Estado:
          </label>
          <select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="pendiente">Pendiente</option>
            <option value="aprobado">Aprobado</option>
            <option value="rechazado">Rechazado</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Nombres:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Apellidos:
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="identity"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Cédula:
          </label>
          <input
            type="text"
            id="identity"
            name="identity"
            value={formData.identity}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="tasa"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Tasa:
          </label>
          <input
            type="number"
            id="tasa"
            name="tasa"
            value={formData.tasa}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="month"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Meses:
          </label>
          <input
            type="number"
            id="month"
            name="month"
            value={formData.month}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="amount"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Monto:
          </label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="cellphone"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Celular:
          </label>
          <input
            type="tel"
            id="cellphone"
            name="cellphone"
            value={formData.cellphone}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="address"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Dirección:
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="expedition"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Lugar de Expedición:
          </label>
          <input
            type="text"
            id="expedition"
            name="expedition"
            value={formData.expedition}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="cert_laboral"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Certificación Laboral:
          </label>
          <input
            type="file"
            id="cert_laboral"
            name="cert_laboral"
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="comp_nomina"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Comprobante de nómina:
          </label>
          <input
            type="file"
            id="comp_nomina"
            name="comp_nomina"
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="fot_cedula"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Fotocopia de la cédula:
          </label>
          <input
            type="file"
            id="fot_cedula"
            name="fot_cedula"
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label
            htmlFor="dec_renta"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Declaración de renta:
          </label>
          <input
            type="file"
            id="dec_renta"
            name="dec_renta"
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>

      {doneStatus && (
        <p className="text-green-700 text-sm mt-4">
          Se actualizó la solicitud correctamente
        </p>
      )}
      <div className="mt-6 flex justify-end">
        <button
          disabled={fetching}
          aria-disabled={fetching}
          onClick={handleUpdateRequest}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {fetching ? "Actualizando..." : "Actualizar"}
        </button>
      </div>
    </div>
  );
}

export default ModalContainer;
