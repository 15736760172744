import { addDoc, collection } from "@firebase/firestore";
import { db } from "../FirebaseConfig.ts";
import { useState } from "react";

const useInsertDoc = () => {
  const [fetching, setFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInsert = async (url, data) => {
    setFetching(true);
    try {
      const res = await addDoc(collection(db, url), data);
      setIsSuccess(true);
      return res;
    } catch (error) {
      console.error("Error insert document:", error);
      setIsSuccess(false);
    } finally {
      setFetching(false);
    }
  };

  return { handleInsert, fetching, isSuccess, setIsSuccess };
};

export default useInsertDoc;
