import { PDFDocument, rgb } from "pdf-lib";
import { planPagos } from "../../../utils/planPagos";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatCurrencyTable } from "../../../utils/formatCurrencyTable";
dayjs.extend(relativeTime);
dayjs.locale("es");

function DownloadPDF({ name, data }) {
  const { schedule } = planPagos(data);

  const generarCertificado = async () => {
    // Crear un nuevo documento PDF
    const pdfDoc = await PDFDocument.create();

    // Agregar una página nueva
    const newPage = pdfDoc.addPage([595.28, 841.89]); // A4 size
    const pageWidth = newPage.getWidth();
    const pageHeight = newPage.getHeight();

    const logoUrl = "/logo_lendy.png"; // Ruta al logo
    const logoBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoBytes);
    const logoDims = logoImage.scale(0.07); // Escala el logo

    newPage.drawImage(logoImage, {
      x: newPage.getWidth() - logoDims.width - 20, // Ajustar posición horizontal
      y: newPage.getHeight() - logoDims.height - 20, // Ajustar posición vertical
      width: logoDims.width,
      height: logoDims.height,
    });

    // Configurar las posiciones y dimensiones de la tabla
    const tableStartX = 50;
    const tableStartY = pageHeight - 100; // Ajusta según el espacio que necesites
    const rowHeight = 20;
    const columnWidths = [50, 90, 80, 90, 70, 70, 70]; // Ancho de cada columna
    const headers = [
      "Cuotas",
      "Fecha de pago",
      "Valor préstamo",
      "Cuota a pagar",
      "Capital",
      "Interés",
      "Saldo deuda",
    ];

    const headerBackgroundColor = rgb(3 / 255, 32 / 255, 99 / 255);

    // Dibujar la cabecera de la tabla
    headers.forEach((header, i) => {
      const x =
        tableStartX + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
      newPage.drawRectangle({
        x,
        y: tableStartY - rowHeight,
        width: columnWidths[i],
        height: rowHeight,
        color: headerBackgroundColor, // Color gris claro
      });

      newPage.drawText(header, {
        x: x + 5,
        y: tableStartY - rowHeight + 5,
        size: 10,
        color: rgb(1, 1, 1),
      });
    });

    // Dibujar las filas de la tabla con datos del plan de pagos
    schedule.forEach((row, rowIndex) => {
      headers.forEach((_, colIndex) => {
        const x =
          tableStartX +
          columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
        const y = tableStartY - (rowIndex + 2) * rowHeight;

        // Dibujar bordes de celdas
        newPage.drawRectangle({
          x,
          y,
          width: columnWidths[colIndex],
          height: rowHeight,
          borderColor: rgb(0, 0, 0), // Color del borde
          borderWidth: 1, // Grosor del borde
        });

        const text = [
          row.cuota.toString(),
          row.fechaPago.toString(),
          formatCurrencyTable(row.valorPrestamo),
          formatCurrencyTable(row.cuotaAPagar),
          formatCurrencyTable(row.capital),
          formatCurrencyTable(row.interes),
          formatCurrencyTable(row.saldoDeuda),
        ][colIndex];

        newPage.drawText(text, {
          x: x + 5, // Margen izquierdo
          y: y + 5, // Margen inferior
          size: 10,
          color: rgb(0, 0, 0),
        });
      });
    });

    // Agregar un título en la parte superior de la página
    newPage.drawText("Crédito", {
      x: 45,
      y: pageHeight - 50,
      size: 18,
      color: rgb(0, 0, 0),
    });

    // Guardar el PDF modificado como bytes
    const pdfBytes = await pdfDoc.save();

    // Crear un Blob con los bytes del PDF generado
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Crear una URL para el Blob
    const url = URL.createObjectURL(blob);

    // Abrir una nueva pestaña con el PDF generado
    const newWindow = window.open(url, "_blank");

    setTimeout(() => {
      newWindow.document.title = "Crédito - " + name;
    }, 100); // Ajusta el tiempo según sea necesario
  };

  return (
    <div className="flex items-center -mt-4 mb-3">
      <button
        onClick={() => generarCertificado()}
        className="bg-primary rounded-lg py-1 px-2 text-black font-semibold mt-3"
      >
        Descargar PDF
      </button>
    </div>
  );
}

export default DownloadPDF;
