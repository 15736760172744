import Spinner from "../../../components/Spinner/index.js";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere.js";
import ContentTasa from "./content.js";

function Tasa({ user }) {
  const { data: dataUser, isFetching: fetchingUser } = useSnapshotWhere(
    "users",
    {
      filter: {
        firstCondition: "id",
        secondCondition: "==",
        thirdCondition: user.uid,
      },
    }
  );

  return !dataUser || fetchingUser ? (
    <Spinner />
  ) : (
    <ContentTasa dataUser={dataUser} />
  );
}

export default Tasa;
