import Spinner from "../../../components/Spinner/index.js";
import React, { useEffect, useState } from "react";
import { db } from "../../../FirebaseConfig.ts";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere.js";

function ListCompany({ user }) {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { data: dataUser, isFetching: fetchingUser } = useSnapshotWhere(
    "users",
    {
      filter: {
        firstCondition: "id",
        secondCondition: "==",
        thirdCondition: user.uid,
      },
    }
  );

  const categoriesCollection = collection(db, "companies");

  useEffect(() => {
    (async () => {
      if (dataUser) {
        try {
          setIsFetching(true);
          const q = query(
            categoriesCollection,
            where("majorCompanyId", "==", dataUser[0]?.majorCompanyId)
          );
          const data = await getDocs(q);

          setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
          console.log(error);

          setIsFetching(false);
        } finally {
          setIsFetching(false);
        }
      }
    })();
  }, [fetchingUser]);

  return (
    <>
      <h2 className="text-xl mb-5">Lista de empresas</h2>
      <div
        className={`relative shadow-md sm:rounded-lg ${
          isFetching ? "" : "overflow-x-auto"
        }`}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Nombre
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Acción
              </th> */}
            </tr>
          </thead>

          <tbody className="bg-white">
            {isFetching ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="100%" className="text-center text-lg py-3">
                  No existen solicitudes
                </td>
              </tr>
            ) : (
              data?.map((el, index) => {
                return (
                  <tr
                    className="odd:bg-white even:bg-gray-50  border-b"
                    key={el.id}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {`${el.name_company}`}
                    </th>
                    <td className="px-6 py-4">{el.email}</td>

                    {/* <td className="px-6 py-4">
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setCurrentRequest(el);
                        }}
                        className="font-medium text-blue-600 hover:underline"
                      >
                        Ver Empresa
                      </button>
                    </td> */}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ListCompany;
