import React, { useState } from "react";
import { useSnapshot } from "../../../../hooks/useSnapshot";
import { useSnapshotWhere } from "../../../../hooks/useSnapshotWhere";
import Spinner from "../../../../components/Spinner";
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../../../../FirebaseConfig.ts";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useUpdateDoc from "../../../../hooks/useUpdateDoc.js";
import { obtenerFechaActual } from "../../../../utils/actualDate.js";
import WithoutCodeudor from "../../../../components/WithoutCodeudor/index.js";

function ModalContainer({ currentRequest, majorCompanyId, setCurrentRequest }) {
  const [dataC, setDataC] = useState({
    name: "",
    email: "",
  });
  const [fetching, setFetching] = useState(false);
  const [done, setDone] = useState(false);
  const [doneStatus, setDoneStatus] = useState(false);
  const [doneCompany, setDoneCompany] = useState(false);
  const [status, setStatus] = useState(currentRequest.status);
  const [loading, setLoading] = useState(false);

  const { data, isFetching } = useSnapshotWhere("codeudor", {
    filter: {
      firstCondition: "requestId",
      secondCondition: "==",
      thirdCondition: currentRequest.id,
    },
  });

  const { data: companies, isFetching: fetchingCompanies } = useSnapshotWhere(
    "companies",
    {
      filter: {
        firstCondition: "majorCompanyId",
        secondCondition: "==",
        thirdCondition: majorCompanyId,
      },
    }
  );

  const { handleUpdate } = useUpdateDoc();

  const handleInsertCodeudor = async () => {
    try {
      setFetching(true);
      const res = await addDoc(collection(db, "codeudor"), {
        ...dataC,
        requestId: currentRequest.id,
        cert_laboral: "",
        comp_nomina: "",
        fot_cedula: "",
        dec_renta: "",
      });

      handleUpdate("requests", currentRequest.id, { codeudor: res.id });
      handleUpdate("codeudor", res.id, { uuid: res.id });

      setFetching(false);
      setDataC({
        name: "",
        email: "",
      });
      setDone(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDone(false);
    } finally {
      setFetching(false);
    }
  };

  const handleUpdateRequest = async () => {
    let data = { status: status };

    if (status === "aprobado") data.start_date = obtenerFechaActual();
    if (status === "aprobado") data.payments = [];
    try {
      setFetching(true);

      handleUpdate("requests", currentRequest.id, data);

      setFetching(false);

      setDoneStatus(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneStatus(false);
    } finally {
      setFetching(false);
    }
  };

  const [company, setCompany] = useState(currentRequest.companyId);
  const handleUpdateCompany = async () => {
    try {
      setFetching(true);

      handleUpdate("requests", currentRequest.id, {
        companyId: company,
      });

      setFetching(false);

      setDoneCompany(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneCompany(false);
    } finally {
      setFetching(false);
    }
  };

  const handleFileUpload = async (file, field) => {
    if (!file) return;

    try {
      setLoading(true);
      const fileRef = ref(storage, `documents/${currentRequest.id}/${field}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);

      await handleUpdate("requests", currentRequest.id, { [field]: url });

      setCurrentRequest({ ...currentRequest, [field]: url });

      alert("Archivo subido exitosamente.");
      setLoading(false);
    } catch (error) {
      console.error("Error subiendo archivo:", error);
      alert("Error al subir archivo.");
      setLoading(false);
    }
  };

  return (
    <div>
      <p className="text-xl font-semibold">
        Editar solicitud de: {currentRequest.name} {currentRequest.lastName}
      </p>
      <hr className="mt-2" />

      <p className="text-left mt-3">Archivos subidos por el empleado:</p>
      {loading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-4 gap-2">
          <Item
            href={currentRequest.cert_laboral}
            name="Certificación Laboral"
            field="cert_laboral"
            handleFileUpload={handleFileUpload}
          />
          <Item
            href={currentRequest.comp_nomina}
            name="Comprobante Nómina"
            field="comp_nomina"
            handleFileUpload={handleFileUpload}
          />
          <Item
            href={currentRequest.dec_renta}
            name="Declaración de Renta"
            field="dec_renta"
            handleFileUpload={handleFileUpload}
          />
          <Item
            href={currentRequest.fot_cedula}
            name="Fotocopia Cédula"
            field="fot_cedula"
            handleFileUpload={handleFileUpload}
          />
        </div>
      )}

      <hr className="mt-5" />

      <div className="grid grid-cols-2 gap-5 mt-5">
        <div>
          <label
            htmlFor="countries"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Estado:
          </label>
          <div className="flex gap-2 items-center">
            <select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="pendiente" disabled>
                Pendiente
              </option>
              <option value="aprobado">Aprobado</option>
              <option value="rechazado">Rechazado</option>
            </select>

            <button
              disabled={fetching}
              aria-disabled={fetching}
              onClick={() => handleUpdateRequest()}
              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Actualizar
            </button>
          </div>
          {doneStatus && (
            <p className="text-green-700 text-sm mt-2">
              Se actualizó el estado correctamente
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="countries"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Empresa:
          </label>
          <div className="flex gap-2 items-center">
            <select
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value=""></option>
              {companies.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name_company}
                </option>
              ))}
            </select>

            <button
              disabled={fetching}
              aria-disabled={fetching}
              onClick={() => handleUpdateCompany()}
              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Actualizar
            </button>
          </div>
          {doneCompany && (
            <p className="text-green-700 text-sm mt-2">
              Empresa relacionada correctamente
            </p>
          )}
        </div>
      </div>

      <hr className="mt-5" />

      <div className="text-left">
        {done && (
          <p className="text-green-700 text-sm mt-2">
            Se agregó correctamente el codeudor, se le envió un correo con toda
            la información
          </p>
        )}
        <p className="mb-0 mt-3">Codeudor: {data[0]?.name}</p>
        {isFetching ? (
          <Spinner />
        ) : data ? (
          data.length > 0 ? (
            <div className="grid grid-cols-4 gap-2">
              <Item
                href={currentRequest.cert_laboral}
                name="Certificación Laboral"
              />
              <Item
                href={currentRequest.comp_nomina}
                name="Comprobante Nómina"
              />
              <Item
                href={currentRequest.dec_renta}
                name="Declaración de Renta"
              />
              <Item href={currentRequest.fot_cedula} name="Fotocopia Cédula" />
            </div>
          ) : (
            <div className="bg-gray-100 rounded-md w-fit p-2 mt-3">
              <p>No hay codeudor</p>
              <div className="mt-3">
                <div className="flex items-center">
                  <div className="flex gap-3">
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Correo"
                      required
                      value={dataC.email}
                      onChange={(e) =>
                        setDataC({ ...dataC, email: e.target.value })
                      }
                    />

                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Nombres y Apellidos"
                      required
                      value={dataC.name}
                      onChange={(e) =>
                        setDataC({ ...dataC, name: e.target.value })
                      }
                    />
                  </div>
                  <button
                    disabled={fetching}
                    aria-disabled={fetching}
                    onClick={() => handleInsertCodeudor()}
                    className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    Agregar Codeudor
                  </button>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <hr className="mt-5" />

      <p className="mb-0 mt-3 text-left">Archivos para enviarle al empleado</p>

      <div className="w-fit">
        <WithoutCodeudor data={currentRequest} status={status} />
      </div>
    </div>
  );
}

export function Item({ href, name, noActive, handleFileUpload, field }) {
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file, field);
    }
  };
  return (
    <div className="relative border rounded-lg mt-5 text-center text-sm px-2 py-3">
      {noActive || !href ? (
        <label className="block bg-red-300 rounded-lg border-red-300 p-3 cursor-pointer">
          Falta: {name}
          <input type="file" className="hidden" onChange={handleChange} />
        </label>
      ) : (
        <a href={href} target="_blank" rel="noreferrer" className="block">
          {name}
        </a>
      )}
    </div>
  );
}

export default ModalContainer;
