import React, { useEffect, useState } from "react";
import { useSnapshot } from "../../../hooks/useSnapshot";
import useUpdateDoc from "../../../hooks/useUpdateDoc.js";
import Spinner from "../../../components/Spinner/index.js";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere.js";

function ContentTasa({ dataUser }) {
  const { data, isFetching } = useSnapshotWhere("tasa", {
    filter: {
      firstCondition: "majorCompanyId",
      secondCondition: "==",
      thirdCondition: dataUser[0]?.majorCompanyId,
    },
  });

  const [numberTasa, setNumberTasa] = useState();
  const [fetching, setFetching] = useState(false);
  const [doneTasa, setDoneTasa] = useState(false);

  const { handleUpdate } = useUpdateDoc();

  const handleInputChange = (e) => {
    const { value } = e.target;

    // Permitir solo números y un punto decimal
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Eliminar cualquier carácter no permitido
    const parts = sanitizedValue.split(".");

    // Asegurarse de que haya como máximo un punto decimal
    if (parts.length > 2) {
      setNumberTasa(parts[0] + "." + parts[1]); // Combinar solo la primera parte con el decimal
    } else {
      setNumberTasa(sanitizedValue);
    }
  };

  const handleUpdateTasa = async () => {
    try {
      setFetching(true);

      handleUpdate("tasa", data[0]?.id, {
        number: numberTasa,
      });

      setFetching(false);

      setDoneTasa(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneTasa(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!isFetching) {
      setNumberTasa(data[0]?.number);
    }
  }, [isFetching]);

  return (
    <div
      className={`${
        isFetching ? "" : "max-w-lg mx-auto my-4 bg-white rounded-lg p-3"
      } `}
    >
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <p className="text-xl font-semibold">
            Tasa actual: {data[0]?.number}
          </p>
          <hr className="mt-2" />

          {doneTasa && (
            <p className="text-green-700 text-sm mt-2">
              Se actualizó la tasa correctamente
            </p>
          )}

          <input
            type="text"
            name="tasa"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
            value={numberTasa}
            onChange={handleInputChange}
          />

          <button
            disabled={fetching}
            aria-disabled={fetching}
            onClick={() => handleUpdateTasa()}
            className="p-2.5 mt-5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Actualizar
          </button>
        </>
      )}
    </div>
  );
}

export default ContentTasa;
