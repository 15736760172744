import useInsertDoc from "../../../hooks/useInsertDoc";
import Modal from "../../../components/Modal";
import { useState } from "react";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere.js";
import useUpdateDoc from "../../../hooks/useUpdateDoc.js";

function SaveSimulator({ data, user }) {
  const { data: dataUser } = useSnapshotWhere("users", {
    filter: {
      firstCondition: "id",
      secondCondition: "==",
      thirdCondition: user?.uid,
    },
  });

  const userData = dataUser[0];

  const { handleInsert, fetching, isSuccess, setIsSuccess } = useInsertDoc();
  const { handleUpdate } = useUpdateDoc();

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    identity: "",
    name: "",
    lastName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return (
      formData.identity.trim() !== "" &&
      formData.name.trim() !== "" &&
      formData.lastName.trim() !== ""
    );
  };

  const saveSimulator = async () => {
    const res = await handleInsert("simulator", {
      ...data,
      status: "pendiente",
      ammount: data.ammount.replace(/\./g, ""),
      user: userData.id,
      userName: userData.name,
      identity: formData.identity,
      name: formData.name,
      lastName: formData.lastName,
      majorCompanyId: userData.majorCompanyId,
    });

    handleUpdate("simulator", res.id, { uuid: res.id });
  };

  return (
    <div className="">
      <div className="w-fit -mt-4 mb-3">
        <button
          onClick={() => {
            setIsOpen(true);
            setIsSuccess(false);
          }}
          className="bg-robin rounded-lg py-1 px-2 text-black font-semibold mt-3"
        >
          Guardar Simulación
        </button>
      </div>
      <Modal showModal={isOpen} setShowModal={setIsOpen}>
        {isSuccess ? (
          <div className="bg-green-200 p-3 rounded-lg mt-4">
            <p>La simulación se ha guardado exitosamente</p>
          </div>
        ) : (
          <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
              Guardar Simulación
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="identity"
                  className="text-sm font-medium text-gray-700"
                >
                  Cédula
                </label>
                <input
                  id="identity"
                  name="identity"
                  value={formData.identity}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-700"
                >
                  Nombres
                </label>
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="lastName"
                  className="text-sm font-medium text-gray-700"
                >
                  Apellidos
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={saveSimulator}
                disabled={!isFormValid() || fetching}
                className={`bg-robin text-black font-semibold rounded-lg py-2 px-4 transition duration-300 ease-in-out transform hover:scale-105 ${
                  !isFormValid() || fetching
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-primary-dark"
                }`}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default SaveSimulator;
