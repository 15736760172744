import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../FirebaseConfig.ts";
import Spinner from "../../../components/Spinner/index.js";
import Loading from "../../../components/Loading/index.js";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../../utils/format.js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { planPagos } from "../../../utils/planPagos.js";
import TablePagos from "./table.js";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere.js";
import DownloadPDF from "./downloadPDF.js";
import ExtractPDF from "./extractPDF.js";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

function PlanDePagos() {
  const { identity, id, idSimulator } = useParams();

  const { data: request, isFetching: loading } = useSnapshotWhere(
    `${idSimulator ? "simulator" : "requests"}`,
    {
      filter: {
        firstCondition: "uuid",
        secondCondition: "==",
        thirdCondition: id ? id : idSimulator,
      },
    }
  );

  const data = request[0];

  if (loading) return <Loading />;

  if (!data) return <p>No hay datos</p>;

  const { schedule, totalCredit, totalInterest } = planPagos(data);
  // Filtramos los pagos realizados hasta el momento
  const pagosRealizados = schedule.filter(({ cuota }) =>
    data.payments.includes(cuota.toString())
  );

  // Sumamos los pagos realizados
  const totalPagado = pagosRealizados.reduce(
    (acc, { cuotaAPagar }) => acc + cuotaAPagar,
    0
  );

  // Calculamos la deuda restante
  const deudaHastaFecha2 = totalCredit - totalPagado;

  return (
    <div className={`relative ${loading ? "" : "overflow-y-auto"} rounded-lg`}>
      <div className="flex justify-start flex-col">
        <p className="text-left text-xl font-semibold mb-5">
          Crédito de {data?.name}
        </p>

        <div className="flex items-center gap-3">
          <DownloadPDF data={data} />
          <ExtractPDF data={data} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 ">
        <div className="mb-5 bg-white text-center p-2 rounded-lg">
          <p className="text-md mb-1">
            Valor del préstamo: <b>{formatNumber(data.ammount)}</b>
          </p>
          <p className="text-md mb-1">
            Tasa de interés anual: <b>{data.tasa}%</b>
          </p>
          <p className="text-md mb-1">
            Periodo de préstamo en meses: <b>{data.month}</b>
          </p>
          <p className="text-md mb-1">
            Fecha de inicio del prestamo: <b>{data.start_date}</b>
          </p>
        </div>
        <div className="mb-5 bg-white text-center p-2 rounded-lg">
          <p className="text-md mb-1">
            Pago mensual:{" "}
            <b>{formatNumber(schedule[0].cuotaAPagar.toFixed(0))}</b>
          </p>
          <p className="text-md mb-1">
            Número de pagos: <b>{data.month}</b>
          </p>
          <p className="text-md mb-1">
            Valor total de los intereses:{" "}
            <b>{formatNumber(totalInterest.toFixed(0))}</b>
          </p>
          <p className="text-md mb-1">
            Costo total del préstamo:{" "}
            <b>{formatNumber(totalCredit.toFixed(0))}</b>
          </p>
          <p className="text-md mb-1 text-red-600 font-bold">
            Deuda pendiente hasta la fecha:{" "}
            <b>{formatNumber(deudaHastaFecha2.toFixed(0))}</b>
          </p>
        </div>
      </div>

      <TablePagos
        loading={loading}
        schedule={schedule}
        payment={idSimulator ? false : true}
        data={data}
      />
    </div>
  );
}

export default PlanDePagos;
