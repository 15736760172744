"use client";

import { useState, useEffect } from "react";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { db } from "../FirebaseConfig.ts";

export const useSnapshotWhereTwo = (collect, { filter, second }) => {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const q = query(
        collection(db, collect),
        where(
          filter.firstCondition,
          filter.secondCondition,
          filter.thirdCondition
        ),
        where(
          second.firstCondition,
          second.secondCondition,
          second.thirdCondition
        )
      );

      const subscriber = onSnapshot(q, {
        next: (snapshot) => {
          const data = [];
          snapshot.docs.forEach((doc) => {
            data.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          setData(data);
          setIsFetching(false);
        },
      });

      return () => subscriber();
    })();
  }, []);

  return { data, setData, isFetching };
};
