import { BrowserRouter, Route, Routes } from "react-router-dom";
import Banner from "./components/Banner";
import Login from "./pages/login/page";
import Platform from "./pages/plataforma/page";
import LayoutPlatform from "./pages/plataforma/layout";
import Simulador from "./pages/plataforma/simulador";
import Companies from "./pages/companies";
import Tasa from "./pages/plataforma/tasa";
import Codeudor from "./pages/codeudor";
import Creditos from "./pages/plataforma/creditos";
import PlanDePagos from "./pages/plataforma/plan-de-pagos";
import Compania from "./pages/plataforma/compania";
import SearchSimulator from "./pages/plataforma/buscar-simulacion";
import ListCompany from "./pages/plataforma/listaEmpresas";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./FirebaseConfig.ts";
import Spinner from "./components/Spinner/index.js";

function RouterWrapper() {
  const [user, loading] = useAuthState(auth);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/empresas" element={<Companies />} />
          <Route path="/codeudor/:id" element={<Codeudor />} />

          <Route path={`/login`} element={<Login />} />
          <Route
            path={`/plataforma`}
            element={
              <LayoutPlatform>
                <Platform user={user} />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/creditos`}
            element={
              <LayoutPlatform>
                {loading ? <Spinner /> : <Creditos user={user} />}
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/lista-empresas`}
            element={
              <LayoutPlatform>
                {loading ? <Spinner /> : <ListCompany user={user} />}
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/simulador/:identity/:id`}
            element={
              <LayoutPlatform>
                <PlanDePagos />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/simulador/:idSimulator`}
            element={
              <LayoutPlatform>
                <PlanDePagos />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/compania/:idCompany/:idRequest`}
            element={
              <LayoutPlatform>
                <Compania />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/buscar-simulacion`}
            element={
              <LayoutPlatform>
                <SearchSimulator user={user} />
              </LayoutPlatform>
            }
          />

          <Route
            path={`/plataforma/simulador`}
            element={
              <LayoutPlatform>
                <Simulador />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/tasa`}
            element={
              <LayoutPlatform>
                {loading || !user ? <Spinner /> : <Tasa user={user} />}
              </LayoutPlatform>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouterWrapper;
